 


// // // App.js
// // import React from "react";
// // import "./App.css";
// // import { FaRegHandshake, FaSearchLocation, FaChartLine, FaRegNewspaper, FaIndustry } from "react-icons/fa";
// // import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa"; // Ensure these are imported

// // const App = () => {
// //   return (
// //     <div className="coming-soon">
// //       <div className="hero">
// //         <h1 className="logo">LandScape Marketplace</h1>
// //         <p className="tagline">The Future of Land Ownership, Simplified.</p>
// //       </div>
      
// //       <div className="offerings">
// //         <div className="offerings-cards">
// //           <div className="offering-card">
// //             <FaSearchLocation className="icon" />
// //             <h3>Land Buying & Selling</h3>
// //             <p>Seamlessly buy and sell land with our streamlined platform. Our easy-to-use interface makes transactions quick, secure, and hassle-free.</p>
// //           </div>
// //           <div className="offering-card">
// //             <FaRegHandshake className="icon" />
// //             <h3>Transparent Ownership</h3>
// //             <p>Access clear and verified ownership records, ensuring every transaction is backed by accurate data. Avoid disputes with our secure system.</p>
// //           </div>
// //           <div className="offering-card">
// //             <FaChartLine className="icon" />
// //             <h3>Market Analytics</h3>
// //             <p>Stay ahead of the market with real-time analytics. Track land prices, trends, and investment opportunities to make informed decisions.</p>
// //           </div>
// //           <div className="offering-card">
// //             <FaRegNewspaper className="icon" />
// //             <h3>Land Leasing Solutions</h3>
// //             <p>Manage land leases effortlessly. Whether you're leasing for agriculture or development, our platform provides full management tools.</p>
// //           </div>
// //           <div className="offering-card">
// //             <FaIndustry className="icon" />
// //             <h3>Business & Industrial Land</h3>
// //             <p>We specialize in commercial and industrial land, offering strategic locations for businesses. Browse tailored options for your business needs.</p>
// //           </div>
// //         </div>
// //       </div>
      
// //       <div className="subscription">
// //         <h2>Stay Updated</h2>
// //         <p>Sign up to be the first to know when we launch!</p>
// //         <form>
// //           <input type="email" placeholder="Enter your email" required />
// //           <button type="submit">Notify Me</button>
// //         </form>
// //       </div>
      
// //       <footer>
// //         <p>Follow Us</p>
// //         <div className="social-icons">
// //           <FaFacebook />
// //           <FaTwitter />
// //           <FaInstagram />
// //           <FaLinkedin />
// //         </div>
// //         <p>© 2024 LandScape Marketplace. All Rights Reserved.</p>
// //       </footer>
// //     </div>
// //   );
// // };

// // export default App;


 


// // App.js
// import React, { useState } from "react";
// import axios from "axios";
// import "./App.css";
// import { FaRegHandshake, FaSearchLocation, FaChartLine, FaRegNewspaper, FaIndustry, FaEnvelope,FaEyeSlash } from "react-icons/fa";
// import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa"; // Ensure these are imported
// import { AiOutlineFileSearch } from "react-icons/ai";


// const App = () => {

//   const [email, setEmail] = useState("");

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // setMessage(""); // Reset message
//     try {
//       const response = await axios.post(
//         'https://land-mart.vercel.app/api/v1/Scripts/addEmail',
//         { email },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if(response.data.success)setEmail('')
//       // setMessage("Thank you for subscribing!");
//     } catch (error) {
//       console.error("Error submitting email:", error);
//       // setMessage("There was an error. Please try again.");
//     }
//   };

//   return (
//     <div className="coming-soon">
//       <div className="hero">
//         <h1 className="logo">BhuMandi</h1>
//         {/* <p className="tagline">The Future of Land Trade, Simplified. </p> */}
//         <p className="tagline">Seamlessly buy and sell land with our streamlined platform. Our easy-to-use interface makes it quick, secure, and hassle-free.</p>



//       </div>
      
//       <div className="offerings">
//         <div className="offerings-cards">


//           <div className="offering-card">
//             <AiOutlineFileSearch className="icon" />
//             <h3>Instant Land Details and One-Click Inquiries</h3>
//             <p>Finding the perfect land has never been simpler! With just one click, you can access detailed land information and enquire directly, making it easy to connect with sellers and make informed buying decisions.</p>
//           </div>


         

//           <div className="offering-card">
//             <FaSearchLocation className="icon" />
//             <h3>Effortless Filtering</h3>
//             <p>Find exactly what you're looking for with advanced filters. Narrow down your search by land type, price, and size to instantly view the best options that fit your criteria.</p>
//           </div>



//           <div className="offering-card">
//             {/* <FaChartLine className="icon" /> */}
//             <FaEyeSlash className="icon" />
//             <h3>Shielding identity</h3>
//             {/* <p>Facilitating anonymous enquiries and postings to encourage free exploration.</p> */}
//             <p>Enquire and list land anonymously, ensuring privacy and security until the deal is finalized. This approach fosters a safe, open environment for transactions, allowing for confident and discreet decision-making.</p>
//           </div>

//           <div className="offering-card">
//             <FaRegHandshake className="icon" />
//             <h3>Streamline Conversations</h3>
//             <p>Our chat feature lets you seamlessly discuss and negotiate buy-sell transactions with audio, text, and image support. Whether you're showcasing products or clarifying details, this all-in-one tool ensures clear communication and faster deals.</p>
//           </div>



       
        
         


//           <div className="offering-card">
//             <FaChartLine className="icon" />
//             <h3>Save and Monitor Efficiently</h3>
//             <p>Keep a close eye on your land interests and track changes over time. Our platform allows you to save lands and monitor updates on market trends, price fluctuations, and new opportunities, ensuring you never miss out on a smart buy.</p>
//           </div>

//           <div className="offering-card">
//             <FaRegNewspaper className="icon" />
//             <h3>Digitalization Benefits</h3>
//             <p>Reducing geographical barriers, giving buyers access to a wider range of lands and helping sellers reach new potential buyers and better pricing.</p>
//           </div>



//         </div>
//       </div>
      
//       <div className="subscription">
//         <h2>Stay Updated</h2>
//         <p>Sign up to be the first to know when we launch!</p>
//         <form
//         onSubmit={handleSubmit}
//         >
//           <input type="email" 
//           placeholder="Enter your email" 
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required />
//           <button type="submit">Notify Me</button>
//         </form>
//       </div>
      
//       <footer>
//         <p>Follow Us</p>
//         <div className="social-icons">
//           <FaFacebook />
//           <FaTwitter />
//           <FaInstagram />
//           <FaLinkedin />
//         </div>
//         <p>© 2024 ENJOYVISTA TECH PRIVATE LIMITED. All Rights Reserved.</p>
//       </footer>
//     </div>
//   );
// };

// export default App;

import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home'; // Adjust path as necessary
import Delete from './pages/Delete'; // Adjust path as necessary
// import NotFound from './pages/NotFound'; // Adjust path as necessary

function App() {
  return (
    <div>
      {/* Navigation Links */}
      {/* <nav>
        <Link to="/">Home</Link> | <Link to="/delete">Delete</Link>
      </nav> */}

      {/* Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/delete" element={<Delete />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
