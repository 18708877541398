import React, { useState } from "react";
import "./Delete.css";
import axios from "axios";

const App = () => {
  const [activeTab, setActiveTab] = useState("delete");
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [note, setNote] = useState("");
  const [toast, setToast] = useState({ message: "", type: "" });

  const baseUrl ='https://land-mart.vercel.app/api/v1'
// const baseUrl ='http://localhost:4000/api/v1'


  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    setStep(1);
    setPhone("");
    setOtp("");
    setNote("");
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10); // Allow only digits, max 10
    setPhone(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isDeleting = activeTab === "delete";
    if (step === 2) {
      try {
        const {data } = await axios.post(`${baseUrl}/verifyaddToDelete`,
           { phn: phone,
            otp
            });

            if(data.success)
                {
        setToast({ message: "Your account is scheduled for deletion in 15 days.", type: "success" });
        setStep(1);
                }
      } catch {
        setToast({ message: "Failed to verify OTP. Please try again.", type: "error" });
      }
      
    } else {
      try {

        const endpoint = isDeleting ? `${baseUrl}/addToDelete` : `${baseUrl}/removeToDelete`;
       const { data } = await axios.post(endpoint, 
          { 
            phn: phone,
          //  otp, 
           comment:note
           });
 
           if(data.success)
       {
         setToast({
          message: isDeleting
            ? "Please verify OTP"
            : "Your account has been removed from the deletion list.",
          type: "success",
        });

       if(isDeleting)
        setStep(2);

       if(!isDeleting)
        setPhone("");

        // setPhone("");
        setOtp("");
        setNote("");
      }

      if(!data.success)
        {
          setToast({ message: data.message, type: "error" });
        }


      } catch(error) {

        // setToast({ message: data.message, type: "error" });
        setToast({ message: "Error. Please try again.", type: "error" });
//    console.log("error",error)
      }
    }
  };

  return (
    <div className="app-container">
      <header>
        <h1>Manage Your Account</h1>
        <p>
          {activeTab === "delete"
            ? "We’re sad to see you leave. Let us know how we can assist you."
            : "Want to stay with us? Remove your account from the deletion list before it’s too late."}
        </p>
      </header>

      <nav className="tab-navigation">
        <button
          className={`tab-button ${activeTab === "delete" ? "active" : ""}`}
          onClick={() => handleTabSwitch("delete")}
        >
          Delete Account
        </button>
        <button
          className={`tab-button ${activeTab === "remove" ? "active" : ""}`}
          onClick={() => handleTabSwitch("remove")}
        >
          Remove from Deletion List
        </button>
      </nav>

      <main className="form-section">
        <form onSubmit={handleSubmit}>

        {step === 1 && (
          <div className="form-group">
            <label htmlFor="phone">
              <span className="form-label">Phone Number:</span>
              <div className="phone-input-wrapper">
                <span className="phone-prefix">+91</span>
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="1234567890"
                  required
                />
              </div>
            </label>
          </div>)}

          {step === 2 && (
            <div className="form-group">
              <label htmlFor="otp">
                <span className="form-label">Enter OTP:</span>
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter the OTP"
                  required
                />
              </label>
            </div>
          )}

          {activeTab === "delete" && step === 1 && (
            <div className="form-group">
              <label htmlFor="note">
                <span className="form-label">Reason for Deletion:</span>
                <textarea
                  id="note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Optional: Let us know why you're considering account deletion..."
                  rows={4}
                ></textarea>
              </label>
            </div>
          )}

          {activeTab === "remove" && step === 1 && (
            <div style={{ visibility: "hidden", height: "80px" }}></div>
          )}

          <button type="submit" className="submit-btn">
            {step === 2 ? "Verify OTP" : activeTab === "delete" ? "Confirm Deletion" : "Remove from List"}
          </button>
        </form>
      </main>

      {toast.message && (
        <div className={`toast ${toast.type}`}>
          <p>{toast.message}</p>
        </div>
      )}
    </div>
  );
};

export default App;